import {Roles} from '@common/enum/roles.enum';

export const HAS_MODULE_ROLES = [
  Roles.ROLE_ACCOUNT_OWNER,
  Roles.ROLE_AREA_MANAGER,
  Roles.ROLE_ACCOUNT_ADMIN,
  Roles.ROLE_WAREHOUSE,
  Roles.ROLE_LISTER,
  Roles.ROLE_ORDER_PROCESSOR,
  Roles.ROLE_PICKER,
  Roles.ROLE_MESSENGER,
  Roles.ROLE_LISTING_SUPERVISOR,
  Roles.ROLE_DISABLED,
];
