/* istanbul ignore file */
import 'web-animations-js/web-animations.min';

import {APP_BASE_HREF, DATE_PIPE_DEFAULT_OPTIONS} from '@angular/common';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {enableProdMode, ErrorHandler, importProvidersFrom, Injectable, provideZoneChangeDetection} from '@angular/core';
import {MAT_AUTOCOMPLETE_DEFAULT_OPTIONS} from '@angular/material/autocomplete';
import {MAT_RIPPLE_GLOBAL_OPTIONS} from '@angular/material/core';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MAT_SELECT_CONFIG} from '@angular/material/select';
import {MatSnackBar} from '@angular/material/snack-bar';
import {bootstrapApplication} from '@angular/platform-browser';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideRouter, TitleStrategy} from '@angular/router';
import {APP_ROUTES} from '@api/components/app/app.routes';
import {ApiService} from '@api/services/api/api.service';
import {AuthService} from '@api/services/auth/auth.service';
import {GlobalMessageService} from '@api/services/global-message/global-message.service';
import {HttpInterceptorService} from '@api/services/http-interceptor/http-interceptor.service';
import {MenuRoutesService} from '@api/services/menu-routes/menu-routes.service';
import {SwaggerService} from '@api/services/swagger/swagger.service';
import {UserDataService} from '@api/services/user-data/user-data.service';
import {DATE_PIPE_CONFIG} from '@common/globals/datePipeConfig';
import {MAT_AUTOCOMPLETE_CONFIG} from '@common/globals/matAutocompleteConfig';
import {MAT_DIALOG_CONFIG} from '@common/globals/matDialogConfig';
import {MAT_FORM_FIELD_CONFIG} from '@common/globals/matFormFieldConfig';
import {MAT_RIPPLE_CONFIG} from '@common/globals/matRippleConfig';
import {MAT_SELECT_DEFAULT_CONFIG} from '@common/globals/matSelectConfig';
import {SentryErrorHandler} from '@common/globals/sentryErrorHandler';
import appVersion from '@common/json/appVersion';
import {CanDeactivateService} from '@common/services/can-deactivate/can-deactivate.service';
import {CommonService} from '@common/services/common-service/common.service';
import {LocalStorageService} from '@common/services/local-storage/local-storage.service';
import {VersionCheckService} from '@common/services/version-check/version-check.service';
import {initSentry} from '@common/statics/initSentry';
import {GlobalMessageCommon} from '@common/ts/globalMessageCommon';
import {
  ARROW_LEFT,
  ARROW_RIGHT,
  CHECK,
  CHEVRON_DOWN,
  CHEVRON_LEFT,
  CHEVRON_RIGHT,
  CHEVRON_UP,
  CODE,
  COG,
  DOTS_HORIZONTAL,
  DOTS_VERTICAL,
  DUPLICATE,
  EYE,
  EYE_SLASH,
  MENU,
  MINUS,
  PLUS,
  REFRESH,
  SAVE,
  SEARCH,
  SWITCH_VERTICAL,
  VIEW_BOARDS,
  X,
  X_CIRCLE,
  X_CIRCLE_SOLID,
} from '@common/ts/heroIcons';
import {HI_ICON_SET_TOKEN} from '@common/ts/heroIconTokens';
import {PageTitleCommon} from '@common/ts/pageTitleCommon';
import {RouteCommon} from '@common/ts/routeCommon';
import {UserDataCommon} from '@common/ts/userDataCommon';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';

import {AppComponent} from './components/app/app.component';
import {ENVIRONMENT} from './environments/environment';

if (ENVIRONMENT.production) {
  enableProdMode();
}

initSentry(ENVIRONMENT.sentryDns, appVersion.apidoc);

@Injectable()
class TemplatePageTitleStrategy extends PageTitleCommon {

  constructor() {
    super('API');
  }

}

bootstrapApplication(AppComponent, {
  providers: [
    provideZoneChangeDetection({
      eventCoalescing: true,
    }),
    provideHttpClient(withInterceptorsFromDi()),
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true},
    {provide: APP_BASE_HREF, useValue: '/'},
    {provide: ErrorHandler, useClass: SentryErrorHandler},
    {provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, useValue: MAT_AUTOCOMPLETE_CONFIG},
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: MAT_RIPPLE_CONFIG},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: MAT_DIALOG_CONFIG},
    {provide: MAT_SELECT_CONFIG, useValue: MAT_SELECT_DEFAULT_CONFIG},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: MAT_FORM_FIELD_CONFIG},
    {provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: DATE_PIPE_CONFIG},
    {provide: UserDataCommon, useExisting: UserDataService},
    {provide: GlobalMessageCommon, useExisting: GlobalMessageService, deps: [MatSnackBar]},
    {provide: RouteCommon, useExisting: MenuRoutesService},
    {provide: HI_ICON_SET_TOKEN, useValue: {
      arrowLeft: ARROW_LEFT,
      arrowRight: ARROW_RIGHT,
      chevronLeft: CHEVRON_LEFT,
      chevronRight: CHEVRON_RIGHT,
      chevronDown: CHEVRON_DOWN,
      chevronUp: CHEVRON_UP,
      xCircleSolid: X_CIRCLE_SOLID,
      cog: COG,
      eye: EYE,
      eyeSlash: EYE_SLASH,
      save: SAVE,
      switchVertical: SWITCH_VERTICAL,
      xCircle: X_CIRCLE,
      x: X,
      minus: MINUS,
      check: CHECK,
      duplicate: DUPLICATE,
      plus: PLUS,
      refresh: REFRESH,
      menu: MENU,
      code: CODE,
      search: SEARCH,
      viewBoards: VIEW_BOARDS,
      dotsHorizontal: DOTS_HORIZONTAL,
      dotsVertical: DOTS_VERTICAL,
    }, multi: true},
    AuthService,
    CommonService,
    ApiService,
    MenuRoutesService,
    LocalStorageService,
    provideAnimations(),
    UserDataService,
    SwaggerService,
    GlobalMessageService,
    VersionCheckService,
    CanDeactivateService,
    {provide: TitleStrategy, useClass: TemplatePageTitleStrategy},
    provideRouter(APP_ROUTES),
    importProvidersFrom(MatDialogModule),
    importProvidersFrom(NgxPageScrollCoreModule),
  ],
});
