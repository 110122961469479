import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogRef} from '@angular/material/dialog';
import {HeroIconComponent} from '@common/components/hero-icon/hero-icon.component';
import {FromEventCommon} from '@common/ts/fromEventCommon';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';

import texts from './retryCall';

@UntilDestroy()
@Component({
  selector: 'app-retry-call',
  standalone: true,
  templateUrl: './retry-call.component.html',
  styleUrl: './retry-call.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    HeroIconComponent,
    MatButtonModule,
  ],
})
export class RetryCallComponent extends FromEventCommon {

  public retryTexts = texts;

  constructor(public dialogRef: MatDialogRef<RetryCallComponent, boolean>) {
    super();
  }

  override handleEnterKeydown(event: Observable<KeyboardEvent>): void {
    event.pipe(untilDestroyed(this)).subscribe(() => this.dialogRef.close(true));
  }

}
