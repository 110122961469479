import {AppCookies} from '@common/statics/cookieHandler';
import {SavedLoginData} from '@common/ts/interfaces';

export function getLoginData<T>(): SavedLoginData<T> {
  return AppCookies.getCookie<SavedLoginData<T>>('loginToken') || {
    companyModules: [],
    expiresIn: null,
    isImpersonate: false,
    itemLocationModules: [],
    loginTime: null,
    roles: [],
    token: '',
    userId: null,
    websocketUser: null,
  };
}
