@if (data?.message) {
  <div class="snackMessage">
    <b>{{ data.title }}</b>
    <p class="secondTitle">{{ globalErrorTexts.description }}</p>
    @if (data.message.code) {
      <div class="col-12 text-center">
        <mat-form-field class="withoutMarginField removeArrows">
          <input [value]="data.message.code" matInput readonly type="text" />
          <app-hero-icon
            matSuffix
            name="duplicate"
            class="heroIcon"
            (cdkCopyToClipboardCopied)="errorCodeTooltip.show()"
            [matTooltip]="globalErrorTexts.copied"
            [matTooltipPosition]="'above'"
            #errorCodeTooltip="matTooltip"
            (mouseenter)="$event.stopImmediatePropagation()"
            (mouseleave)="errorCodeTooltip.hide()"
            [cdkCopyToClipboard]="data.message.code" />
        </mat-form-field>
      </div>
      @if (serviceDeskURL) {
        <a href="{{ serviceDeskURL }}/create/26" target="_blank" rel="noopener noreferrer">
          {{ globalErrorTexts.openTicket }}
        </a>
      }
    }
  </div>
}
<app-hero-icon (click)="globalErrorComponentRef.dismiss()" name="x-circle" class="closeIcon" />
