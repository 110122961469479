import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

export interface ProjectVersionDTO {
  hash: string;
  hashDate: string;
  oldHash: string;
}

@UntilDestroy()
@Injectable()
export class VersionCheckService {

  public storageName = 'versionHash';

  constructor(public http: HttpClient) {
  }

  checkVersion(): void {
    this.http.get<ProjectVersionDTO>(`./version.json?t=${new Date().getTime()}`).pipe(untilDestroyed(this)).subscribe({
      next: (response) => {
        if (response && this.hashChanged(response.hash)) {
          window.location.reload();
        }
      },
    });
  }

  hashChanged(newHash: string): boolean {
    const hash = localStorage.getItem(this.storageName);
    if (hash) {
      if (hash !== newHash) {
        localStorage.removeItem(this.storageName);
        localStorage.setItem(this.storageName, newHash);
        return true;
      }
    } else {
      localStorage.setItem(this.storageName, newHash);
    }
    return false;
  }

}
