import {inject} from '@angular/core';
import {CommonService} from '@common/services/common-service/common.service';
import {checkToken} from '@common/statics/checkToken';
import {UserDataCommon} from '@common/ts/userDataCommon';
import {catchError, Observable, of, switchMap} from 'rxjs';

export abstract class AuthCommon<U, T> {

  public commonSrv = inject(CommonService);

  protected constructor(public userDataService: UserDataCommon<U>) {
  }

  authenticationCheck(roles: T[]): Observable<boolean> {
    if (roles.length) {
      return this.commonSrv.hasActiveToken().pipe(switchMap((hasActiveToken) => {
        if (hasActiveToken) {
          return this.commonSrv.tokenCheck().pipe(switchMap((result) => {
            if (result) {
              if (checkToken(result)) {
                return this.authHandler(roles);
              }
              return this.goToLogin();
            }
            this.removeData();
            return of(false);
          }), catchError(() => this.goToLogin()));
        }
        this.removeData();
        return of(false);
      }));
    }
    this.userDataService.currentUrl = null;
    return of(true);
  }

  abstract authHandler(roles: T[]): Observable<boolean>;

  abstract removeData(): void;

  abstract goToLogin(): Observable<boolean>;

}
