import {Injectable, OnDestroy} from '@angular/core';
import {getLoginData} from '@common/statics/getLoginData';
import {BACKEND_URL} from '@common/ts/config';
import {HubConnection, HubConnectionBuilder, LogLevel} from '@microsoft/signalr';

@Injectable()
export class SignalrService implements OnDestroy {

  private connection: HubConnection = null;

  hasConnection(): boolean {
    return Boolean(this.connection);
  }

  tryConnect(): void {
    if (!this.hasConnection()) {
      const {token} = getLoginData();
      if (token) {
        this.connection = new HubConnectionBuilder().configureLogging(LogLevel.Critical).withAutomaticReconnect().withUrl(
          `${BACKEND_URL}/client-communication/pingHub`, {
            accessTokenFactory: () => token,
          }).build();
      }
    }
  }

  connectCall(methodName: string, newMethod: () => void): void {
    this.tryConnect();
    if (this.hasConnection()) {
      this.stopConnection(false);
      this.connection.on(`/queue/${methodName}`, newMethod);
      this.startConnection();
    }
  }

  private startConnection(): void {
    if (this.hasConnection()) {
      this.connection.start();
    }
  }

  private stopConnection(needReset = true): void {
    if (this.hasConnection()) {
      this.connection.stop().finally(() => {
        if (needReset) {
          this.connection = null;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.stopConnection();
  }

}
