import {DOCUMENT} from '@angular/common';
import {Directive, inject} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {VersionCheckService} from '@common/services/version-check/version-check.service';
import {addPrefix} from '@common/ts/interfaces';
import {UserDataCommon} from '@common/ts/userDataCommon';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {fromEvent} from 'rxjs';

@UntilDestroy()
@Directive()
export class AppCommon<U> {

  public document = inject(DOCUMENT);
  public router = inject(Router);
  public versionCheckSrv = inject(VersionCheckService);

  protected constructor(public userDataService: UserDataCommon<any>) {
    this.setAppHeightProp();
    this.router.events.pipe(untilDestroyed(this)).subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          this.userDataService.previousUrl = this.userDataService.currentUrl;
          this.userDataService.currentUrl = decodeURIComponent(event.url) as addPrefix<U, '/'>;
        } else if (event instanceof NavigationEnd) {
          if (location.hostname.includes('shopiago')) {
            this.versionCheckSrv.checkVersion();
          }
          this.navigationEndHandler();
        }
      },
    });
    fromEvent(window, 'resize').pipe(untilDestroyed(this)).subscribe(() => {
      this.setAppHeightProp();
    });
  }

  protected navigationEndHandler(): void {
  }

  setAppHeightProp(): void {
    this.document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  }

}
