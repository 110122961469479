import {Routes} from '@angular/router';

export const LOGIN_REDIRECT: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    redirectTo: 'login',
  },
];

export const NOT_FOUND_REDIRECT: Routes = [
  {
    path: 'notfound',
    loadChildren: () => import('@common/components/page-not-found/page-not-found.routes'),
  },
  {
    path: '**',
    redirectTo: '/notfound',
  },
];
