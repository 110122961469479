<div class="snackMessage">
  <b>{{ data.title }}</b>
  <br />
  @for (messageItem of messages; track $index; let isLast = $last) {
    <span [innerHTML]="messageItem"></span>
    @if (!isLast) {
      <br />
    }
  }
</div>
<app-hero-icon (click)="globalMessageComponentRef.dismiss()" name="x-circle" class="closeIcon" />
