import {AppCookies} from '@common/statics/cookieHandler';
import {isLocalhost} from '@common/statics/isLocalhost';
import {TokenCheckDTO} from '@common/ts/interfaces';
import * as Sentry from '@sentry/angular-ivy';

export function checkToken(token: TokenCheckDTO): boolean {
  if (token?.client_id === 'browser') {
    AppCookies.setCookie('loginTokenLastChecked', new Date().getTime().toString());
    if (!isLocalhost()) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: token.userExtraData.id,
          username: token.user_name,
          email: token.user_name,
          authorities: token.authorities,
          itemLocationModules: token.itemLocationModules,
          companyModules: token.companyModules,
        });
      });
    }
    return true;
  }
  return false;
}
