export enum Roles {
  ROLE_DISABLED = 0,
  ROLE_ACCOUNT_OWNER = 1,
  ROLE_USER = 2,
  ROLE_TEAM_LEADER = 4,
  ROLE_SHOP_MANAGER = 20,
  ROLE_SHOP_VOLUNTEER = 21,
  ROLE_WAREHOUSE = 22,
  ROLE_LISTER = 23,
  ROLE_ORDER_PROCESSOR = 24,
  ROLE_PICKER = 25,
  ROLE_MESSENGER = 26,
  ROLE_ACCOUNT_ADMIN = 27,
  ROLE_SHOP_MANAGER_LISTER = 28,
  ROLE_LISTING_SUPERVISOR = 29,
  ROLE_SHOP_ASSISTANT = 33,
  ROLE_SHOP_LISTER = 34,
  ROLE_SHOP_ASSISTANT_LISTER = 35,
  ROLE_TRADE_INDICATE_PRICE = 36,
  ROLE_AREA_MANAGER = 37,
}
