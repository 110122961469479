import {ChangeDetectionStrategy, Component, effect, ElementRef, inject, input, Renderer2, viewChild} from '@angular/core';
import {ViewBox} from '@common/components/hero-icon/hero-icon.model';
import {DEFAULT_VIEW_BOX} from '@common/components/hero-icon/hero-icon.variables';
import {HI_ICON_SET_TOKEN} from '@common/ts/heroIconTokens';
import {HeroIconName, HeroIconsList, KebabToCamel} from '@common/ts/interfaces';

@Component({
  selector: 'app-hero-icon',
  templateUrl: './hero-icon.component.html',
  styleUrl: './hero-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class HeroIconComponent {

  private readonly icons = inject<HeroIconsList[]>(HI_ICON_SET_TOKEN).reduce((icons, iconSet) => ({
    ...icons,
    ...iconSet,
  }));

  public svg = viewChild<ElementRef<SVGElement>>('svgRef');
  public class = input<string>('');
  public name = input<HeroIconName>(null);

  constructor(private readonly renderer: Renderer2) {
    effect(() => {
      this.renderIcon();
    });
  }

  renderIcon(): void {
    if (this.name() && this.svg()) {
      const name = (this.name().toLowerCase().replace(/[^a-zA-Z\d]+(.)/g, (_m, chr) => chr.toUpperCase())) as KebabToCamel<HeroIconName>,
        isSolidIcon = this.name().includes('-solid'),
        svgElement = this.svg().nativeElement;
      if (isSolidIcon) {
        this.renderer.setAttribute(svgElement, 'fill', 'currentColor');
      }
      this.renderer.setAttribute(svgElement, 'viewBox', this.getViewBoxValue(DEFAULT_VIEW_BOX));
      this.renderer.setProperty(svgElement, 'innerHTML', this.icons[name]);
    }
  }

  getViewBoxValue({x, y, height, width}: ViewBox): string {
    return `${x} ${y} ${height} ${width}`;
  }

}
