import {ErrorHandler, Injectable} from '@angular/core';
import {isLocalhost} from '@common/statics/isLocalhost';
import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  handleError(error: any): void {
    if (!isLocalhost()) {
      Sentry.captureException(error.originalError || error);
    } else {
      console.error(error);
    }

    if (/Loading chunk (.*)+ failed/.test(error.message)) {
      window.location.reload();
    }
  }

}
