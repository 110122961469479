export enum HttpCodes {
  InternetDisconnected = 0,
  Success = 200,
  Created = 201,
  IMUsed = 226,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  Notfound = 404,
  ValidationError = 422,
  CustomError = 424,
  UpgradeRequired = 426,
}
