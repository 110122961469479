export enum BackofficeRoles {
  ROLE_BO_MANAGER = 10,
  ROLE_TRADE_BO = 38,
  ROLE_BO_OPS = 39,
  ROLE_BO_OPS_MGMT = 40,
  ROLE_BO_FINANCE = 41,
  ROLE_BO_COLLECTION_AGENT = 42,
  ROLE_BO_COLLECTION_LOGISTIC_EXTERNAL_PARTNER = 43,
  ROLE_ADMIN = 666,
}
