import {isLocalhost} from '@common/statics/isLocalhost';
import {JSONParse} from '@common/statics/jsonParse';
import {Environment} from '@common/ts/environment';
import {CookieNames} from '@common/ts/interfaces';
import {clearFunction, memoize} from 'memoize-cache-decorator';

export class AppCookies {

  @memoize()
  static getEnvPrefix(): string {
    const env = `${Environment.getEnv()}_`;
    if (window.location.host.includes('apidoc')) {
      return `${env}api_`;
    } else if (window.location.host.includes('backoffice')) {
      return `${env}backoffice_`;
    } else if (isLocalhost()) {
      return `${env}local_`;
    }
    return env;
  }

  @memoize()
  static getCookie<T>(cookieName: CookieNames): T {
    const name = `${AppCookies.getEnvPrefix()}${cookieName}`,
      nameLenPlus = name.length + 1;
    return decodeURIComponent(document.cookie).split(';').map((c) => c.trim()).filter((cookie) =>
      cookie.substring(0, nameLenPlus) === `${name}=`).map((cookie) =>
      JSONParse<T>(decodeURIComponent(cookie.substring(nameLenPlus)), null))[0] || null;
  }

  static setCookie(cookieName: CookieNames, data: string): void {
    AppCookies.removeCookieCache();
    document.cookie = `${AppCookies.getEnvPrefix()}${cookieName}=${data}; expires=${new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365).toUTCString()}; ${!isLocalhost() ? 'secure; path=/; domain=.shopiago.com' : 'path=/'}`;
  }

  static removeCookie(cookieName: CookieNames): void {
    document.cookie = `${AppCookies.getEnvPrefix()}${cookieName}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; ${!isLocalhost() ? 'secure; path=/; domain=.shopiago.com' : 'path=/'}`;
  }

  static removeCookieCache(): void {
    clearFunction(this.getCookie);
  }

}
