import {Location} from '@angular/common';
import {inject} from '@angular/core';
import {Params, Router} from '@angular/router';
import {addPrefix} from '@common/ts/interfaces';

export class RouteCommon<T> {

  public router = inject(Router);
  public location = inject(Location);

  menuStates(route: T | addPrefix<T, '/'>, id: string = null): void {
    const path = id === null ? route : `${String(route)}/${id}`;
    this.location.go(String(path));
  }

  menuRoutes(route: T | addPrefix<T, '/'>, id: string = null, queryParams: Params = {}): Promise<boolean> {
    return this.router.navigate([id === null ? route : `${String(route)}/${id}`], {queryParams});
  }

  removeParam(): void {
    this.router.navigateByUrl(this.router.url.substring(0, this.router.url.indexOf('?')));
  }

}
