import {ClipboardModule} from '@angular/cdk/clipboard';
import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {HeroIconComponent} from '@common/components/hero-icon/hero-icon.component';
import {FieldTitlePipe} from '@common/pipes/field-title/field-title.pipe';
import {SERVICE_DESK} from '@common/ts/config';
import {Environment} from '@common/ts/environment';
import {GlobalErrorsSnackBarDTO} from '@common/ts/interfaces';

import texts from './globalError';

@Component({
  selector: 'app-global-error',
  templateUrl: './global-error.component.html',
  styleUrl: './global-error.component.scss',
  imports: [
    ClipboardModule,
    MatTooltipModule,
    MatInputModule,
    FieldTitlePipe,
    HeroIconComponent,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalErrorComponent {

  public globalErrorTexts = texts;
  public data = inject<GlobalErrorsSnackBarDTO>(MAT_SNACK_BAR_DATA);
  public serviceDeskURL = Environment.getEnv() === '' ? SERVICE_DESK : '';

  constructor(public globalErrorComponentRef: MatSnackBarRef<GlobalErrorComponent>) {
  }

}
