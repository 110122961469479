import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {PRIVACY_POLICY_URL} from '@common/ts/config';
import {CookieName} from '@common/ts/interfaces';

import texts from './cookieNotification';

@Component({
  selector: 'app-cookie-notification',
  templateUrl: './cookie-notification.component.html',
  styleUrl: './cookie-notification.component.scss',
  imports: [
    MatButtonModule,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookieNotificationComponent {

  public showNotification = false;
  public privacyPolicyUrl = PRIVACY_POLICY_URL;
  public cookieNotificationTexts = texts;
  public cookieName = input(null, {
    transform: (value: CookieName) => {
      this.showNotification = value && !localStorage.getItem(value);
      return value;
    },
  });

  closeNotification(): void {
    if (this.cookieName()) {
      this.showNotification = !this.showNotification;
      localStorage.setItem(this.cookieName(), 'allowed');
    }
  }

}
