import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LocalStorageModuleTypes} from '@common/enum/localStorageModuleTypes';
import {JSONParse} from '@common/statics/jsonParse';
import {BACKEND_URL} from '@common/ts/config';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable, of, tap} from 'rxjs';

@UntilDestroy()
@Injectable()
export class LocalStorageService<S> {

  public userId: string = null;
  private storageDataChanged = false;

  constructor(public http: HttpClient) {
  }

  markAsUnchanged(): void {
    this.storageDataChanged = false;
  }

  getAttribute<K extends(keyof S), V = any>(attribute: K): K extends null ? V : K extends keyof S ? S[K] : S | null {
    if (localStorage.getItem(this.userId)) {
      const storage = JSONParse(localStorage.getItem(this.userId), undefined);
      return storage[attribute] !== undefined ?
        JSONParse<K extends null ? V : K extends keyof S ? S[K] : S>(storage[attribute], null) : null;
    }
    return null;
  }

  setAttribute<K extends(keyof S), V = any>(
    attribute: K, data: K extends null ? V : K extends Extract<keyof S, string> ? S[K] : S, storageDataChanged = true): void {
    if (this.userId) {
      const storage = localStorage.getItem(this.userId) ? JSONParse<Record<string, any>>(localStorage.getItem(this.userId), {}) : {};
      storage[attribute as string] = JSON.stringify(data);
      localStorage.setItem(this.userId, JSON.stringify(storage));
      this.storageDataChanged = storageDataChanged;
    }
  }

  loadLocalStorageData(userId: string, moduleId: LocalStorageModuleTypes): void {
    this.http.get<any>(`${BACKEND_URL}/account/accountData/getUserLocalstorageData?moduleId=${moduleId}`).pipe(
      untilDestroyed(this)).subscribe({
      next: (storageData) => {
        if (storageData) {
          localStorage.setItem(userId, JSON.stringify(storageData));
        }
      },
    });
  }

  saveLocalStorageData(moduleId: LocalStorageModuleTypes): Observable<HttpErrorResponse | boolean> {
    const storageData = localStorage.getItem(this.userId);
    if (Boolean(this.userId) && storageData && this.storageDataChanged) {
      return this.http.post<boolean>(`${BACKEND_URL}/account/accountData/setUserLocalstorageData?moduleId=${moduleId}`,
        storageData).pipe(tap((response) =>
        this.storageDataChanged = typeof response === 'boolean' ? response : false));
    }
    return of(true);
  }

}
