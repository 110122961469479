/* istanbul ignore file */

import {EnvUrl} from '@common/statics/getEnvURL';

export const MAIN_PAGE_URL = 'https://www.shopiago.com/';
export const BACKEND_URL = EnvUrl.getEnvURL(null);
export const SERVICE_DESK = 'https://worldofbuzz.atlassian.net/servicedesk/customer/portal/2';
export const FACEBOOK_URL = 'https://www.facebook.com/shopiagosoftware/';
export const X_URL = 'https://x.com/shopiago';
export const LINKED_IN_URL = 'https://www.linkedin.com/company/shopiago/';
export const TERMS_OF_USE_URL = 'https://www.shopiago.com/termsofuse';
export const PRIVACY_POLICY_URL = 'https://www.shopiago.com/privacypolicy';
export const MAINTENANCE_URL = 'https://www.shopiago.com/techcorner/maintenance';
export const ROAD_MAP = 'https://www.shopiago.com/roadmap';

