import {getLoginData} from '@common/statics/getLoginData';
import {clearFunction, memoize} from 'memoize-cache-decorator';

export class UserRoles {

  @memoize({resolver: (roles) => roles.join()})
  static hasRole<T>(values: T[]): boolean {
    const {roles} = getLoginData<T>();
    return roles.length ? roles.some((r) => values.includes(r)) : false;
  }

  static removeRoleCache(): void {
    clearFunction(this.hasRole);
  }

}
