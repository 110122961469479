import {KeyCodes} from '@common/enum/keyCodes.enum';
import {prevent} from '@common/statics/prevent';
import {EMPTY, filter, fromEvent, Observable, of, switchMap} from 'rxjs';

export abstract class FromEventCommon {

  protected constructor(public isGlobalEventSource = true) {
    if (this.isGlobalEventSource) {
      this.attachEnterKeydown(window);
    }
  }

  abstract handleEnterKeydown(_event: Observable<KeyboardEvent>): void;

  protected attachEnterKeydown(eventSourceEl: HTMLDivElement | HTMLElement | Window): void {
    const enterKeydownEvent = fromEvent<KeyboardEvent>(eventSourceEl, 'keydown').pipe(
      filter(({key}) => key === KeyCodes.ENTER), switchMap((event) => {
        if (event.target instanceof Element && !event.target.className.includes('cdk-textarea-autosize')) {
          prevent(event);
          return of(event);
        }
        return EMPTY;
      }),
    );
    this.handleEnterKeydown(enterKeydownEvent);
  }

}
