import {Routes} from '@angular/router';
import {LOGIN_REDIRECT, NOT_FOUND_REDIRECT} from '@common/globals/routes';

export const APP_ROUTES: Routes = [
  ...LOGIN_REDIRECT,
  {
    path: 'login',
    loadChildren: () => import('@api/components/landing-pages/login/login.routes'),
  },
  {
    path: 'docs',
    loadChildren: () => import('@api/components/main-pages/docs/docs.routes'),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('@api/components/main-pages/dashboard/dashboard.routes'),
  },
  ...NOT_FOUND_REDIRECT,
];
