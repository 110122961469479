import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'objectKeys',
  standalone: true,
})
export class ObjectKeysPipe implements PipeTransform {

  transform(object: unknown): string[] {
    return object !== null ? Object.keys(object) : [];
  }

}
