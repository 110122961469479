export enum KeyCodes {
  A = 'a',
  C = 'c',
  DOWN_ARROW = 'ArrowDown',
  END = 'End',
  ENTER = 'Enter',
  ESC = 'Escape',
  HOME = 'Home',
  I = 'i',
  L = 'l',
  LEFT_ARROW = 'ArrowLeft',
  O = 'o',
  P = 'p',
  RIGHT_ARROW = 'ArrowRight',
  S = 's',
  SPACE = ' ',
  UP_ARROW = 'ArrowUp',
}
