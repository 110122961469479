<div class="cookieNotification row g-0 px-3 {{ showNotification ? 'showNotification' : '' }}">
  <p class="col-12 col-sm-10 d-flex align-self-center mb-3 mb-sm-0">
    <span>
      {{ cookieNotificationTexts.description }}
      <a [href]="privacyPolicyUrl" target="_blank">{{ cookieNotificationTexts.learnMore }}</a>
    </span>
  </p>
  <div class="col-12 col-sm-2 d-flex justify-content-center justify-content-sm-end">
    <button (click)="closeNotification()" class="saveBtn" mat-button>{{ cookieNotificationTexts.accept }}</button>
  </div>
</div>
