import {ChangeDetectionStrategy, Component} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SwaggerService} from '@api/services/swagger/swagger.service';
import {UserDataService} from '@api/services/user-data/user-data.service';
import {CookieNotificationComponent} from '@common/components/cookie-notification/cookie-notification.component';
import {SignalrService} from '@common/services/signalr/signalr.service';
import {ApiUrls} from '@common/ts/apiFormInterfaces';
import {AppCommon} from '@common/ts/appCommon';
import {untilDestroyed} from '@ngneat/until-destroy';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    CookieNotificationComponent,
    RouterModule,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SwaggerService, SignalrService],
})
export class AppComponent extends AppCommon<ApiUrls> {

  constructor(public override userDataService: UserDataService,
              public signalR: SignalrService,
              public swaggerService: SwaggerService) {
    super(userDataService);
  }

  override navigationEndHandler(): void {
    if (this.userDataService.userData && !this.swaggerService.services.value.length) {
      this.swaggerService.loadServices();
    }
    if (!this.signalR.hasConnection()) {
      this.signalR.connectCall('userDataChange', () => {
        this.userDataService.refreshUserData().pipe(untilDestroyed(this)).subscribe();
      });
    }
  }

}
