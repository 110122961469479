import {Injectable} from '@angular/core';
import {MenuRoutesService} from '@api/services/menu-routes/menu-routes.service';
import {UserDataService} from '@api/services/user-data/user-data.service';
import {ApiRoles} from '@common/enum/apiRoles.enum';
import {UserRoles} from '@common/statics/userRoles';
import {ApiUrls} from '@common/ts/apiFormInterfaces';
import {AuthCommon} from '@common/ts/authCommon';
import {Observable, of, switchMap} from 'rxjs';

@Injectable()
export class AuthService extends AuthCommon<ApiUrls, ApiRoles> {

  constructor(public routerSrv: MenuRoutesService,
              public override userDataService: UserDataService) {
    super(userDataService);
  }

  override authHandler(roles: ApiRoles[]): Observable<boolean> {
    if (UserRoles.hasRole(roles)) {
      return this.userDataService.userDataCheck().pipe(switchMap((checked) => {
        if (checked) {
          this.userDataService.httpUpdate.next(false);
          return of(true);
        }
        return of(false);
      }));
    }
    return this.goToLogin();
  }

  goToLogin(): Observable<boolean> {
    this.routerSrv.menuRoutes('login');
    return of(false);
  }

  removeData(): void {
    this.userDataService.dataRemover();
    this.routerSrv.menuRoutes('login');
  }

}
