import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {GridRequest} from '@common/globals/gridRequest';
import {GridResponse} from '@common/globals/gridResponse';
import {setResponseMessage} from '@common/statics/setResponseMessage';
import {BACKEND_URL} from '@common/ts/config';
import {SystemAccountCreateDTO, SystemAccountRequestDTO, SystemAccountResetDTO, UserGridListDTO} from '@common/ts/interfaces';
import {Observable} from 'rxjs';

import texts from './apiService';

@Injectable()
export class ApiService {

  public serviceUrlPath = `${BACKEND_URL}/account`;

  constructor(public http: HttpClient) {
  }

  createSystemAccount(request: SystemAccountRequestDTO): Observable<SystemAccountCreateDTO> {
    return this.http.post<SystemAccountCreateDTO>(`${this.serviceUrlPath}/create`, request,
      setResponseMessage({
        errorMessage: texts.getTokenRequestFailed,
      }));
  }

  serviceAccountList(request: GridRequest): Observable<GridResponse<UserGridListDTO>> {
    return this.http.post<GridResponse<UserGridListDTO>>(`${this.serviceUrlPath}/listowned`, request);
  }

  resetSystemAccount(name: string): Observable<SystemAccountResetDTO> {
    return this.http.get<SystemAccountResetDTO>(`${this.serviceUrlPath}/reset/${name}`,
      setResponseMessage({
        errorMessage: texts.getNewCodeFailed,
      }));
  }

}
