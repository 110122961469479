import {HubspotTokenResponse} from '@common/ts/interfaces';

export function setToken(tokenData: HubspotTokenResponse): void {
  if (window.HubSpotConversations && window.hsConversationsSettings?.identificationToken !== tokenData?.visitorAccessToken) {
    window.HubSpotConversations.clear({resetWidget: true});
    window.hsConversationsSettings = {
      loadImmediately: false,
      identificationEmail: tokenData?.email || '',
      identificationToken: tokenData?.visitorAccessToken || '',
    };
    window.HubSpotConversations.widget.load();
  }
}
