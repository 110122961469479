import {Injectable} from '@angular/core';
import {ApiUrls} from '@common/ts/apiFormInterfaces';
import {MenuOverlaySubOptions} from '@common/ts/interfaces';
import {UserDataCommon} from '@common/ts/userDataCommon';

@Injectable()
export class UserDataService extends UserDataCommon<ApiUrls> {

  getSettingsSubOptions(): MenuOverlaySubOptions<any>[] {
    return [];
  }

  dataRemover(): void {
    this.mainDataRemover();
  }

}
