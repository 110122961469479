import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

interface FormType<U> {
  canDeactivate: (_nextUrl: U) => Observable<boolean>;
}

@Injectable()
export class CanDeactivateService<U> {

  protected activeComponent: FormType<U> = null;

  setComponent<T extends FormType<U>>(event: T): void {
    this.activeComponent = event;
  }

  canDeactivate(): Observable<boolean> {
    return typeof this.activeComponent?.canDeactivate === 'function' ? this.activeComponent?.canDeactivate(null) : of(true);
  }

}
