import {isLocalhost} from '@common/statics/isLocalhost';
import type {Environments} from '@common/ts/interfaces';
import {memoize} from 'memoize-cache-decorator';

export class Environment {

  @memoize()
  static getEnv(): Environments {
    if (!isLocalhost()) {
      const locationArray = window.location.host.split('.');
      return locationArray.length === 4 ? locationArray[0] as Environments : '';
    }
    return 'dev';
  }

}
